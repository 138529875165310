<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <h6 class="mb-5 text-body-1">
              {{ `${$t('reservations.not_created')}: ${computedConflicts}` }}
            </h6>
            <v-data-table
              :headers="computedTableColumns"
              :items="dataListTable"
              item-key="id"
              :options.sync="options"
              :loading="loading"
              :loading-text="$t('table.loading_text')"
              :no-data-text="$t('table.no_data')"
              :header-props="{sortByText: $t('table.sort_by')}"
              hide-default-footer
            >
              <template #[`item.incomplete_reason`]="{item}">
                <span class="text-no-wrap">{{ item.incomplete_reason ? item.incomplete_reason : 'No Reason' }}</span>
              </template>

              <template #[`item.include_video`]="{item}">
                <span class="text-no-wrap">{{ item.include_video === 'Y' ? item.video_id ? `id: ${item.video_id}` : $t('reservations.scheduled') : $t('reservations.no_included') }}</span>
              </template>

              <template #[`item.reservation_date`]="{item}">
                <span class="text-no-wrap">{{ formatUSADate(item.reservation_date) }}</span>
              </template>

              <template #[`item.price`]="{item}">
                <span class="text-no-wrap">{{ formatCurrency(item.price) }}</span>
              </template>

              <template #[`item.status_str`]="{item}">
                <v-chip
                  small
                  :color="resolveStatusVariant(item.status)"
                  :class="`${resolveStatusVariant(item.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  {{ item.status_str }}
                </v-chip>
              </template>

              <template #[`item.actions`]="{item}">
                <v-tooltip
                  v-if="item.status !== 'C'"
                  bottom
                >
                  <template #activator="{ on: tooltip, attrs }">
                    <v-icon
                      size="20"
                      class="me-2"
                      color="info"
                      v-bind="attrs"
                      v-on="{ ...tooltip }"
                      @click="onShow(item)"
                    >
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </template>
                  <span>{{ $t('tooltip.detail') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>

          <!-- <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          ></options-buttom-tab> -->

          <options-buttom-block
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="true"
            :info-required="true"
            :no-cancel="true"
            :no-arrow="true"
            :no-delete="true"
            @onCancel="$emit('cancel')"
          />
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiEyeOutline } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
} from '@core/utils/validation'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency, formatUSADate, useRouter } from '@core/utils'

// import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import OptionsButtomBlock from '@/views/components/options-buttom-block/OptionsButtomBlock.vue'

export default {
  components: {
    OptionsButtomBlock,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      required: true,
    },
    playersGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { router } = useRouter()
    const form = ref(null)
    const computedIsOpen = computed({
      get: () => props.isOpen,
      set: val => emit('update:is-open', val),
    })
    const dataListTable = computed(() => {
      if (props.dataParams) return props.dataParams.reservations

      return []
    })
    const computedConflicts = computed(() => {
      if (props.dataParams && props.dataParams.conflicts && Array.isArray(props.dataParams.conflicts)) return props.dataParams.conflicts.map(e => formatUSADate(e)).join(', ')

      return ''
    })

    // { text: t('reservations.incomplete_reason').toUpperCase(), value: 'incomplete_reason', show: true },
    const computedTableColumns = computed(() => [
      { text: t('transactions.date').toUpperCase(), value: 'reservation_date', show: true },
      { text: t('transactions.price').toUpperCase(), value: 'price', show: true },
      { text: t('videos.video').toUpperCase(), value: 'include_video', show: true },
      { text: t('status.status').toUpperCase(), value: 'status_str', show: true },
      {
        text: t('tooltip.actions').toUpperCase(),
        value: 'actions',
        align: 'center',
        sortable: false,
        show: true,
      },
    ])
    const loading = ref(false)
    const options = ref({
      sortBy: ['start_date'],
      sortDesc: [true],
    })

    const dataComputed = computed(() => props.dataParams)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const resolveStatusVariant = status => {
      if (['pending', 'N'].includes(status)) return 'warning'
      if (['active', 'A', 'F'].includes(status)) return 'success'
      if (['inactive', 'I', 'C'].includes(status)) return 'error'

      return 'primary'
    }

    const onShow = item => {
      router.push({
        name: 'views-reservations-schedule',
        params: {
          reservationParams: {
            reservationId: item.id,
            reservationDate: item.reservation_date,
            rangeDate: item.reservation_date,
            range: 0,
            tabDay: 1,
          },
        },
      })
    }

    return {
      // data
      form,

      options,
      loading,

      // computed
      dataComputed,
      statusOptions,
      dataListTable,
      computedTableColumns,
      computedConflicts,
      computedIsOpen,

      // methods
      changeTab,
      onSaveAction,
      onShow,
      resolveStatusVariant,
      formatCurrency,
      formatUSADate,

      // validations
      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      onlyNumberDot,
      onlyNumbers,

      // i18n
      t,

      icons: {
        mdiEyeOutline,
      },
    }
  },
}
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }
  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;

    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 0.03125em !important;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
</style>
