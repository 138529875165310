<template>
  <div class="h-full">
    <v-form
      ref="form"
      class="multi-col-validation px-5 pt-3 pb-10"
    >
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ $t('reservations.date_and_time') }}</label>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-if="!computedEditable"
            v-model="startDateComputed"
            class="text-body-1"
            :label="`${t('transactions.date')}`"
            color="primary"
            outlined
            dense
            persistent-hint
            :readonly="!computedEditable"
          ></v-text-field>

          <v-menu
            v-else
            v-model="modalDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDateComputed"
                :label="t('transactions.date')"
                outlined
                readonly
                dense
                class="text-body-1"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservationData.reservation_date"
              class="text-body-1"
              color="secondary"
              :min="minDate"
              @input="modalDate = false"
              @change="onSelectDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="reservationData.start_time"
            :label="t('transactions.start_time')"
            :items="computedMinutes"
            item-text="value"
            item-value="value"
            dense
            outlined
            class="text-body-1"
            :append-icon="icons.mdiMenuDown"
            hide-details
            @change="onSelectStartTime"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="reservationData.reservation_time"
            :label="t('transactions.end_time')"
            :items="endTimeMinutes"
            item-text="text"
            item-value="value"
            dense
            outlined
            class="text-body-1"
            :append-icon="icons.mdiMenuDown"
            hide-details
            no-data-text="No time available"
            @change="onSelectEndTime"
          ></v-autocomplete>
        </v-col>

        <v-col
          v-if="minuteWarnText && isValidateForm"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="error"
          >
            <template #prepend>
              <v-icon class="me-2">
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${minuteWarnText}` }}
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          class="d-flex align-center"
          fluid
        >
          <v-checkbox
            v-model="reservationData.make_recurring"
            :label="t('videos.make_recurring')"
            true-value="Y"
            false-value="N"
            color="secondary"
            class="ma-0 pa-0 text-body-1 font-weight-medium"
            :disabled="!reservationData.reservation_time"
            hide-details
            @change="onSelectMakeRecurring"
          />
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-select
            v-model="reservationData.frequency"
            dense
            outlined
            class="text-body-1"
            :label="t('reservations.frequency')"
            :items="recurringFrequencyOptions"
            item-text="text"
            item-value="value"
            @change="onVerifyRecurringReservation"
          ></v-select>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="startDateComputed"
            class="text-body-1"
            :label="t('transactions.from_date')"
            color="primary"
            outlined
            dense
            persistent-hint
            hide-details
            disabled
          />
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateComputed"
                :label="t('transactions.to_date')"
                outlined
                readonly
                dense
                class="text-body-1"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservationData.end_date"
              class="text-body-1"
              color="secondary"
              :min="minEndDate"
              @input="modalTo = false"
              @change="onVerifyRecurringReservation"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y' && !reservationData.end_date && isValidateForm"
          cols="12"
        >
          <v-alert
            v-for="alert in recurringAlerts"
            :key="alert.message"
            class="mb-0"
            :type="alert.color"
          >
            <template #prepend>
              <v-icon class="me-2">
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ alert.message }}
          </v-alert>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          class="d-flex align-center"
        >
          <v-icon
            color="info"
            class="mr-1"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          <label>
            End after <strong>{{ endAfter }}</strong> bookings
          </label>
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ t('fields.field') }}</label>
        </v-col>

        <v-col cols="12">
          <custom-radios
            v-if="fielsSelected"
            :radio-content="fieldList"
            :selected-radio.sync="fielsSelected"
            :slide-display="true"
            :grid-column="{ cols: '12', sm: '3' }"
            @update:selectedRadio="onSelectField"
          />
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">Add-ons</label>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <!-- @click.prevent="onIncludeAddOns" -->
          <label
            class="label-custom-input custom-checkbox rounded cursor-pointer"
            :class="isIncludeAddOns === 'Y' ? 'active' : ''"
            @click.prevent="onIncludeAddOns"
          >
            <div>
              <v-checkbox
                v-model="isIncludeAddOns"
                true-value="Y"
                false-value="N"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @change="() => calFullPrice(false)"
                @click.stop="onIncludeAddOns"
              />
            </div>
            <div class="flex-grow-1">
              <div class=" align-center mb-1">
                <h6 class="cr-title text-base">
                  {{ includeAddOnsOption.title }}
                </h6>
                <v-spacer />
              </div>
              <p class="text-sm mb-0">
                {{ includeAddOnsOption.subtitle }}
              </p>
            </div>
          </label>
        </v-col>

        <!-- challenge -->
        <v-col
          cols="12"
          class="pb-0"
          md="9"
        >
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <label
            class="label-custom-input custom-checkbox rounded cursor-pointer"
            :class="isChallenge === 'Y' ? 'active' : ''"
            @click.prevent="onIncludeChallenge"
          >
            <div>
              <!-- @change="() => calFullPrice(false)" -->
              <v-checkbox
                v-model="isChallenge"
                true-value="Y"
                false-value="N"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @click.stop="onIncludeChallenge"
              />
            </div>
            <div class="flex-grow-1">
              <div class=" align-center mb-1">
                <h6 class="cr-title text-base">
                  Challenge
                </h6>
                <v-spacer />
              </div>
              <!-- <p class="text-sm mb-0">
                {{ includeAddOnsOption.subtitle }}
              </p> -->
            </div>
          </label>
        </v-col>
        <!-- challenge -->

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ t('reservations.pricing_method') }}</label>
        </v-col>

        <v-col
          cols="12"
          md="auto"
          class="d-flex align-center"
        >
          <v-btn-toggle
            v-model="selectedPricingType"
            mandatory
            block
            outlined
            active-class="white--text pricing-type-active"
          >
            <v-btn
              outlined
              class="black--text"
              @click="onSelectPricingType('flat')"
            >
              {{ t('reservations.flat_rate') }}
            </v-btn>
            <!-- remove challenge -->
            <v-btn
              v-if="isChallenge === 'N'"
              outlined
              class="black--text"
              @click="onSelectPricingType('per_participant')"
            >
              {{ t('reservations.per_participant') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex align-center"
        >
          <v-text-field
            v-model.number="reservationData.price"
            :label="t('transactions.price')"
            type="number"
            class="text-body-1"
            outlined
            prefix="$"
            dense
            hide-details
            @input="e => {
              if (!charUnique(e, '.') || regexValidator(reservationData.price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                reservationData.price = null
                reservationPrice = 0
                fullPrice = 0
              } else {
                onchangePerParticipantPrice(e)
              }
            }"
            @keypress="onlyNumberDot"
          />
        </v-col>

        <v-col
          cols="12"
          class="py-1"
        >
          <span><span class="font-weight-bold">{{ `${formatCurrency(fullPrice)}` }}</span>{{ ` ${t('reservations.inc_taxes_add_ons')}` }}</span>
        </v-col>

        <v-col
          v-if="reservationData.price === null && isValidateForm"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="error"
          >
            <template #prepend>
              <v-icon class="me-2">
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${t('transactions.price')} ${t('required.null')}` }}
          </v-alert>
        </v-col>

        <v-col
          v-if="false"
          cols="12"
          class="d-flex align-center"
        >
          <v-icon
            color="info"
            class="mr-1"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          <label>
            {{ t('reservations.tota_includ_taxes_addons') }}
          </label>
        </v-col>

        <v-col
          cols="12"
          md="12"
        >
          <!-- remove challenge -->
          <participants
            :is-open="computedIsOpen"
            :participants.sync="participants"
            :facility="facility"
            :players-qty="reservationData.players_qty || 1"
            :players-qty-booking="playersQtyLocal || 1"
            :per-participant-price="perParticipantPrice"
            :remaining-amount="remainingAmount"
            :is-validate-form="isValidateForm"
            :is-challenge="isChallenge === 'Y'"
            :payment-type="reservationData.reservation_type"
            :data-params="reservationData"
            @updateLocal="val => updatePlayersQtyLocal(val)"
            @selectPlayersQty="val => playersQtySelected = val"
            @recalculatePayment="calFullPrice(false)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-start"
        >
          <v-btn
            color="secondary"
            class="me-3"
            @click="saveAction('Y')"
          >
            {{ t('tooltip.create') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="() => {
              confirmCancel()
            }"
          >
            {{ t('tooltip.discard') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="isModalOpen"
      max-width="420px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('notify_players.other_phone_numbers') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(phoneInput, index) in phoneInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-autocomplete
                    v-model="phoneInput.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneInput.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneInput.value"
                    :label="t('notify_players.phone')"
                    dense
                    outlined
                    class="pl-2"
                    :class="{ 'mb-2': index > 0 }"
                    :disabled="!phoneInput.code"
                    :hide-details="index > 0"
                    :rules="(index === 0) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                    :append-icon="index > 0 ? icons.mdiClose : null"
                    @click:append="deletePhoneNumber(index)"
                    @keydown.enter.prevent="addPhoneNumber"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
              >
                <a
                  href="javascript:void(0);"
                  class="text-body-1 ma-0 btn-add secondary--text text-decoration-none"
                  @click="addPhoneNumber"
                >
                  + {{ t('notify_players.add_phone') }}
                </a>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <div class="d-flex justify-space-between">
              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="isModalOpen = false"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <search-players-group
      :is-open.sync="isSearchGroupModalOpen"
      :facility="facility"
      :facilities="facilities"
      :players-group-data.sync="playersGroupData"
    />

    <v-navigation-drawer
      v-model="isContactModalOpen"
      :right="!$vuetify.rtl"
      persistent
      touchless
      app
      temporary
      width="100%"
      style="z-index: 5"
    >
      <div
        id="reservation-new-form"
        class="h-full"
      >
        <div class="drawer-header d-flex align-center mb-0">
          <span class="font-weight-semibold text-base text--primary">{{ $t('tooltip.new') }} {{ t('contacts.contact') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isContactModalOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="formContactDialog"
          class="pa-5"
        >
          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row
              align="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.fullname"
                  :label="t('users.fullname')"
                  dense
                  outlined
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.email"
                  :label="t('users.email')"
                  dense
                  outlined
                  :rules="contactData.email ? [emailValidator] : []"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    v-model="phoneCode"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                        width: 35%;
                      "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneNumber"
                    :label="t('users.phone')"
                    dense
                    outlined
                    :disabled="!phoneCode"
                    class="pl-1"
                    :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                    @keypress="onlyNumbers"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <v-btn
                  color="secondary"
                  class="me-3"
                  @click="onCreateContact"
                >
                  {{ t('tooltip.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { ref, computed, watch, onMounted, onBeforeMount, getCurrentInstance } from '@vue/composition-api'
import {
  mdiCalendar,
  mdiMenuDown,
  mdiClose,
  mdiInformationOutline,
  mdiAlert,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  createReservation,
  createRecurringReservation,
  getContactFacilitySearch,
  getContactCategoryActiveFacility,
  createContact,
  getReservationAvailabilityTime,
  verifyRecurringReservation,
  recalculateReservationPayment,
} from '@api'
import { success, error } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import { required, integerValidator, regexValidator, charUnique, between, emailValidator } from '@core/utils/validation'
import { formatCurrency, trimSpace, validateDateRange } from '@core/utils'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import { avatarText, title } from '@core/utils/filter'
import moment from 'moment'
import Swal from 'sweetalert2'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import {
  DURATION_OPTIONS,
  TIME_OPTIONS,
  sumMinutesOptions,
  convertHour12To24,
  convertHour24To12,
} from '@core/utils/reservation'
import parsePhoneNumber from 'libphonenumber-js'
import useAppConfig from '@core/@app-config/useAppConfig'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

// import CustomCheckboxes from '@core/components/custom-checkboxes/CustomCheckboxes.vue'
// import ReservationNewFormView from './ReservationNewView.vue'
import CustomRadios from '@core/components/custom-radios/CustomRadios.vue'
import searchPlayersGroup from '../components/searchPlayersGroup.vue'
import Participants from '../components/Participants.vue'

export default {
  name: 'ReservationNewForm',
  components: {
    // ReservationNewFormView,
    searchPlayersGroup,
    CustomRadios,
    Participants,

    // CustomCheckboxes,
  },
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
      required: true,
    },
    types: {
      type: Array,
      default: () => [],
      required: true,
    },
    openTime: {
      type: String,
      default: '00:00',
      required: true,
    },
    closeTime: {
      type: String,
      default: '23:29',
      required: true,
    },
    date: {
      type: String,
      default: '',
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => {},
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
      required: true,
    },
    group: {
      type: Number,
      default: 0,
      required: true,
    },
    facilities: {
      type: Array,
      default: () => [],
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    playersGroups: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
    showAllowVideo: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t, tInContext } = useUtils()
    const { userData } = useCryptoJs()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, validatePhones } = usePhone()
    const { isDark } = useAppConfig()
    const vm = getCurrentInstance().proxy

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    const form = ref(null)
    const isValidateForm = ref(false)

    const modalDate = ref(false)
    const modalStartTime = ref(false)
    const modalFrom = ref(false)
    const modalTo = ref(false)
    const dateFrom = ref(null)
    const dateTo = ref(null)
    const dateNow = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const maxDate = ref(
      new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const minDate = ref(
      new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const currentDate = ref(new Date())

    const computedMinutes = computed(() => sumMinutesOptions(props.openTime, props.closeTime))
    const endTimeMinutes = ref([])

    const weekdaysShort = computed(() => [
      { text: t('weekdays_short.mon'), value: 0 },
      { text: t('weekdays_short.tue'), value: 1 },
      { text: t('weekdays_short.wed'), value: 2 },
      { text: t('weekdays_short.thu'), value: 3 },
      { text: t('weekdays_short.fri'), value: 4 },
      { text: t('weekdays_short.sat'), value: 5 },
      { text: t('weekdays_short.sun'), value: 6 },
    ])

    const fielsSelected = ref('')
    const fieldList = ref([])
    const recurringAlerts = ref([])
    const endAfter = ref(0)
    const playersQtyLocal = ref(0)

    const selectedPricingType = ref(0)
    const includeAddOns = ref(['incline_add_ons'])
    const timerIncludeAddOns = ref(null)

    // remove challenge
    const isChallenge = ref('N')
    const isIncludeAddOns = ref('Y')
    const includeAddOnsOption = ref({
      title: t('reservations.video_recording'),
      subtitle: '$0.00',
      value: 'incline_add_ons',
    })
    const includeAddOnsContent = computed(() => [includeAddOnsOption.value])
    const paymentTypeSelected = ref('flat')
    const paymentTypeList = ref([
      {
        desc: null,
        title: 'One Payment',
        value: 'flat',
      },
      {
        desc: null,
        title: 'Split Payment',
        value: 'per_participant',
      },
    ])

    const computedDataParams = computed(() => props.dataParams)
    const computedEditable = computed(() => props.editable)
    const computedDate = computed(() => props.date)
    const computedGroup = computed(() => props.group || null)
    const computedFacility = computed(() => props.facility || null)
    const groupsOptions = computed(() => props.groups)
    const facilitiesOptions = computed(() => props.facilities)
    const fieldsOptions = computed(() => props.fields)
    const playersGroupsOptions = computed(() => props.playersGroups)
    const computedOnSitePaymentMethods = computed(() => props.onSitePaymentMethods)
    const computedIsAdmin = computed(() => userData.value.role === 'A')
    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([{ value: null, code: null, phone: null }])
    const computedPhoneNumbers = computed(() => phoneInputs.value
      .filter(e => e.phone)
      .map(e => e.phone)
      .join(', '))

    const formContactDialog = ref(null)
    const contacts = ref([])
    const contactSelectedId = ref(null)
    const categories = ref([])
    const autoContact = ref(null)
    const isContactModalOpen = ref(false)
    const contactSelected = ref(null)
    const contactData = ref({
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
    })
    const computedContacts = computed(() => {
      if (contactSelected.value) return `${contactSelected.value.fullname || ''}`

      return null
    })
    const searchLoading = ref(false)
    const searchQuery = ref(null)
    const timerSearch = ref(null)
    const phoneCode = ref('')
    const phoneNumber = ref('')

    const showOnsitePayment = ref(false)
    const selectedOnsitePayment = ref(null)
    const isSearchGroupModalOpen = ref(false)
    const playersGroupData = ref([])
    const computedPlayersGroup = computed({
      get: () => playersGroupData.value.map(e => e.circle_name).join(', '),
      set: val => {
        if (val) playersGroupData.value = val
        else playersGroupData.value = []
      },
    })

    const fieldSelected = ref(null)
    const pricePerPlayer = ref(0)
    const timer = ref(null)
    const timerAvaility = ref(null)
    const reservationPrice = ref(null)
    const fullPrice = ref(0)
    const perParticipantPrice = ref(0)
    const remainingAmount = ref(0)
    const minuteWarnText = ref(null)
    const endDateWarnText = ref(null)
    const contactWarnText = ref(null)
    const minuteOptions = ref(DURATION_OPTIONS)
    const timeOptions = ref(TIME_OPTIONS)

    const recurringFrequencyOptions = computed(() => [
      { text: t('billing_cycle_types.daily'), value: 'daily' },
      { text: t('billing_cycle_types.weekly'), value: 'weekly' },
      { text: t('billing_cycle_types.monthly'), value: 'monthly' },
    ])
    const skillLevelOptions = computed(() => [
      { text: t('skill_lavels.rookie'), value: 'rookie' },
      { text: t('skill_lavels.intermediate'), value: 'intermediate' },
      { text: t('skill_lavels.pro'), value: 'pro' },
    ])

    const playersQtySelected = ref(null)
    const participants = ref([
      {
        id: null,
        user_match_id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: perParticipantPrice.value || 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'Y',
        avatar: null,
        checked: false,
        edited: false,
      },
    ])
    const initialData = {
      // contact_id: null,
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_ids: null,
      user_console_id: null,
      reservation_type: 'flat',
      reservation_date: null,
      start_time: null,
      start_date: null,
      end_date: null,
      reservation_time: 15,
      include_video: 'Y',
      price: null,
      players_qty: null,
      weekdays: [],
      repeat_n_weeks: 1,
      make_recurring: 'N',
      phone_numbers_list: [],
      frequency: 'daily',
    }
    const reservationData = ref(initialData)

    const postData = ref({
      // contact_id: null,
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_ids: null,
      user_console_id: null,
      reservation_type: 'flat',
      reservation_date: null,
      start_time: null,
      start_date: null,
      end_date: null,
      reservation_time: 15,
      include_video: 'Y',
      price: null,
      players_qty: null,
      weekdays: [],
      repeat_n_weeks: 1,
      make_recurring: 'N',
      phone_numbers_list: [],
      frequency: 'daily',
    })

    const startDateComputed = computed({
      get: () => {
        if (reservationData.value.reservation_date) return moment(reservationData.value.reservation_date).format('ddd, MMM DD, YYYY')

        return moment(dateNow.value).format('ddd, MMM DD, YYYY')
      },
      set: val => {
        if (val) reservationData.value.reservation_date = val
        else reservationData.value.reservation_date = dateNow.value
      },
    })
    const endDateComputed = computed({
      get: () => moment(reservationData.value.end_date || dateNow.value).format('ddd, MMM DD, YYYY'),
      set: val => {
        reservationData.value.end_date = val || dateNow.value
      },
    })
    const minEndDate = computed(() => {
      if (reservationData.value.reservation_date) return moment(reservationData.value.reservation_date).add(1, 'days').format('YYYY-MM-DD')

      return dateNow.value
    })

    const updatePlayersQtyLocal = val => {
      playersQtyLocal.value = val
    }

    const getStartTime24 = () => {
      let st = ''
      if (reservationData.value.start_time && (reservationData.value.start_time.includes('AM') || reservationData.value.start_time.includes('PM'))) {
        st = convertHour12To24(reservationData.value.start_time)
      } else {
        st = reservationData.value.start_time
      }

      return st
    }

    const onSelectType = type => {
      if (type === 'pick_up') reservationData.value.group_id = null
      else if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
      else reservationData.value.group_id = computedGroup.value

      if (type !== 'group') {
        reservationData.value.circle_ids = []
        computedPlayersGroup.value = []
      }

      // if (type === 'pick_up' || type === 'group') {
      //   reservationData.value.contact_id = null
      // }
    }

    const calPrice = () => Math.round((reservationData.value.reservation_time / 60) * fieldSelected.value.booking_hourly_price * 100) / 100

    const initPrice = () => {
      if (fieldSelected.value) {
        const mapPrice = calPrice()

        // if (reservationData.value.reservation_type === 'per_participant') {
        //   mapPrice /= fieldSelected.value.players_qty
        // }
        reservationPrice.value = Math.round(mapPrice * 100) / 100
      } else {
        reservationPrice.value = 0
        fullPrice.value = 0
        perParticipantPrice.value = 0
        reservationData.value.price = 0
      }

      reservationData.value.price = reservationPrice.value
    }

    const calFullPrice = async (setPrice = true) => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        if (fieldSelected.value) {
          const mapPrice = calPrice()

          // if (reservationData.value.reservation_type === 'per_participant') {
          //   mapPrice /= fieldSelected.value.players_qty
          // }
          reservationPrice.value = Math.round(mapPrice * 100) / 100
          if (setPrice) {
            reservationData.value.price = reservationPrice.value
          }

          const amountPaid = participants.value
            .filter(e => e.paid === 'Y' || e.edited)
            .reduce((a, b) => a + b.final_amount, 0)
          const playersPaidQty = participants.value.filter(e => e.paid === 'Y' || e.edited).length
          let resp = null
          if (reservationData.value.reservation_time && reservationData.value.field_id) {
            resp = await recalculateReservationPayment({
              field_id: reservationData.value.field_id,
              reservation_time: reservationData.value.reservation_time,
              price: reservationData.value.price || 0,
              include_video: reservationData.value.include_video,
              players_qty: playersQtySelected.value ? playersQtySelected.value : reservationData.value.players_qty,
              players_paid_qty: playersPaidQty,
              amount_paid: amountPaid,
              reservation_type: reservationData.value.reservation_type,
            })
          }

          if (resp && resp.ok) {
            if (resp.data.video_base_price > 0) includeAddOnsOption.value.subtitle = `$${resp.data.video_base_price}`
            fullPrice.value = resp.data.total_price
            remainingAmount.value = fullPrice.value - amountPaid
            perParticipantPrice.value = resp.data.players_final_amount
            pricePerPlayer.value = resp.data.players_final_amount
          } else {
            reservationPrice.value = 0
            if (setPrice) {
              reservationData.value.price = reservationPrice.value
            }
            fullPrice.value = 0
            perParticipantPrice.value = 0
            remainingAmount.value = 0
            reservationData.value.price = 0
          }
        } else {
          reservationPrice.value = 0
          fullPrice.value = 0
          perParticipantPrice.value = 0
          remainingAmount.value = 0
          reservationData.value.price = 0
        }

        // if (fieldSelected.value) {
        //   let mapPrice = calPrice()
        //   let videoPrice = 0

        //   if (reservationData.value.include_video === 'Y') videoPrice = fieldSelected.value.hourly_pricing

        //   if (reservationData.value.reservation_type === 'per_participant') {
        //     videoPrice /= fieldSelected.value.players_qty
        //     mapPrice /= fieldSelected.value.players_qty
        //     perParticipantPrice.value = Math.round(mapPrice * 100) / 100
        //   } else {
        //     perParticipantPrice.value = Math.round((mapPrice / fieldSelected.value.players_qty) * 100) / 100
        //   }

        //   reservationPrice.value = Math.round(mapPrice * 100) / 100
        //   fullPrice.value = mapPrice + Math.round(videoPrice * 100) / 100
        // } else {
        //   reservationPrice.value = 0
        //   fullPrice.value = 0
        //   perParticipantPrice.value = 0
        //   reservationData.value.price = 0
        // }

        // if (setPrice) reservationData.value.price = reservationPrice.value
      }, 500)
    }

    const calPricePerPlayer = async () => {
      let resp = null
      if (reservationData.value.reservation_time && reservationData.value.field_id) {
        resp = await recalculateReservationPayment({
          field_id: reservationData.value.field_id,
          reservation_time: reservationData.value.reservation_time,
          price: reservationData.value.price || 0,
          include_video: reservationData.value.include_video,
          players_qty: playersQtySelected.value ? playersQtySelected.value : reservationData.value.players_qty,
          players_paid_qty: 0,
          amount_paid: 0,
          reservation_type: reservationData.value.reservation_type,
        })
      }

      if (resp && resp.ok) {
        pricePerPlayer.value = resp.data.players_final_amount
      } else {
        pricePerPlayer.value = 0
      }
    }

    const onIncludeAddOns = async () => {
      if (timerIncludeAddOns.value) clearTimeout(timerIncludeAddOns.value)
      timerIncludeAddOns.value = setTimeout(async () => {
        if (isIncludeAddOns.value === 'Y') isIncludeAddOns.value = 'N'
        else isIncludeAddOns.value = 'Y'

        reservationData.value.include_video = isIncludeAddOns.value

        await calFullPrice(false)

        // reservationData.value.price = reservationPrice.value
      }, 50)
    }

    const onchangePerParticipantPrice = async e => {
      if (
        !charUnique(e, '.')
        || regexValidator(reservationData.value.price, '^[0-9]{0,9}(\\.?[0-9]{0,2})?$', 'Incorrect format') !== true
      ) {
        reservationData.value.price = null
        reservationPrice.value = 0
        fullPrice.value = 0
      } else if (reservationData.value.reservation_type === 'per_participant') {
        perParticipantPrice.value = Math.round(e * 100) / 100
      }

      await calFullPrice(false)
    }

    const onVerifyRecurringReservation = async () => {
      if (reservationData.value.make_recurring === 'Y' && reservationData.value.reservation_time) {
        const resp = await verifyRecurringReservation(
          reservationData.value.field_id,
          reservationData.value.reservation_date,
          reservationData.value.end_date,
          getStartTime24(),
          reservationData.value.reservation_time,
          reservationData.value.frequency,
        )

        if (resp.ok) {
          recurringAlerts.value = []
        } else {
          recurringAlerts.value = resp.data.alerts
        }
        endAfter.value = resp.data.reservations_qty
      }
    }

    const onSelectEndTime = async time => {
      if (time) {
        reservationData.value.end_time = convertHour12To24(endTimeMinutes.value.find(e => e.value === time).text)
        await onVerifyRecurringReservation()
        await calFullPrice()
      }
    }

    const filterDurations = async field => {
      if (timerAvaility.value) clearTimeout(timerAvaility.value)
      timerAvaility.value = setTimeout(async () => {
        const start = getStartTime24()
        if (field && reservationData.value.reservation_date && start) {
          const resp = await getReservationAvailabilityTime(
            field,
            reservationData.value.reservation_date,
            start,
          )
          if (resp.ok && resp.data) {
            minuteWarnText.value = null

            // minuteOptions.value = DURATION_OPTIONS.filter(e => resp.data.includes(e.value))
            // reservationData.value.reservation_time = minuteOptions.value.at(0).value
            endTimeMinutes.value = resp.data.map(e => ({
              ...e,
              text: convertHour24To12(e.end_time),
              value: e.reservation_time,
            }))

            // reservationData.value.reservation_time = endTimeMinutes.value.at(0).value
            if (endTimeMinutes.value.length >= 4) reservationData.value.reservation_time = endTimeMinutes.value.at(3).value
            else if (endTimeMinutes.value.length > 0) reservationData.value.reservation_time = endTimeMinutes.value.at(endTimeMinutes.value.length - 1).value
            reservationData.value.end_time = convertHour12To24(endTimeMinutes.value.at(0).text)

            await onSelectEndTime(reservationData.value.reservation_time)

            // calFullPrice()
          } else {
            minuteWarnText.value = resp.message.text

            // minuteOptions.value = []
            reservationData.value.make_recurring = 'N'
            endTimeMinutes.value = []
            reservationData.value.end_time = null
            reservationData.value.reservation_time = null
            reservationData.value.price = 0
            reservationPrice.value = 0
            fullPrice.value = 0
            perParticipantPrice.value = 0
            remainingAmount.value = 0
          }
        }
      }, 300)
    }

    const onSelectDuration = async () => {
      await calFullPrice()
    }

    const onSelectMakeRecurring = async recurring => {
      if (recurring !== 'Y') {
        // reservationData.value.repeat_n_weeks = 1
        reservationData.value.start_date = null
        reservationData.value.end_date = null
        reservationData.value.weekdays = []
      } else {
        const momentData = moment(reservationData.value.reservation_date)
        const day = momentData.day()

        // momentData.add(1, 'y')
        reservationData.value.weekdays = [day === 0 ? 6 : day - 1]
        reservationData.value.start_date = reservationData.value.reservation_date
        reservationData.value.end_date = momentData.add(1, 'days').format('YYYY-MM-DD')

        await onVerifyRecurringReservation()
      }
    }

    const onSelectPricingType = async type => {
      reservationData.value.reservation_type = type

      // if (type === 'flat' && participants[0].is_organizer === 'N') {
      //   participants[0].id = null
      //   participants[0].user_match_id = null
      //   participants[0].contact_id = null
      //   participants[0].user_app_id = null
      //   participants[0].fullname = null
      //   participants[0].phone_number = null
      //   participants[0].email = null
      //   participants[0].final_amount = perParticipantPrice.value || 0
      //   participants[0].paid = 'N'
      //   participants[0].payment_medium = null
      //   participants[0].onsite_payment_method_id = null
      //   participants[0].onsite_payment_method_name = null
      //   participants[0].empty = true
      //   participants[0].enabled = true
      //   participants[0].is_organizer = 'Y'
      //   participants[0].avatar = null
      //   participants[0].checked = false
      //   participants[0].edited = false
      // }

      await calFullPrice(false)
    }

    // remove challenge
    const onIncludeChallenge = async () => {
      if (timerIncludeAddOns.value) clearTimeout(timerIncludeAddOns.value)
      timerIncludeAddOns.value = setTimeout(async () => {
        if (isChallenge.value === 'Y') isChallenge.value = 'N'
        else {
          isChallenge.value = 'Y'
          onSelectPricingType('flat')
        }

        reservationData.value.is_challenge = isChallenge.value

        // await calFullPrice(false)

        // reservationData.value.price = reservationPrice.value
      }, 50)
    }

    const onSelectField = async field => {
      reservationData.value.field_id = field
      const finded = fieldsOptions.value.find(e => e.id === field)
      if (finded) {
        fieldSelected.value = finded
        reservationData.value.players_qty = null
        reservationData.value.players_qty = finded.players_qty

        // includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`
      }

      await filterDurations(field)
      setTimeout(async () => {
        await calFullPrice()
        await onVerifyRecurringReservation()
      }, 500)
    }

    const onSelectAddOns = async selected => {
      reservationData.value.include_video = selected.includes('incline_add_ons') ? 'Y' : 'N'
      await calFullPrice()
    }

    const onSelectStartTime = async () => {
      await filterDurations(reservationData.value.field_id)
      await calFullPrice()
      await onVerifyRecurringReservation()
    }

    const allowedMinutes = m => m % 15 === 0

    const allowedHours = h => {
      if (dateNow.value === reservationData.value.reservation_date) return h >= currentDate.value.getHours()

      return true
    }

    const onSelectDate = async startDate => {
      if (validateDateRange(startDate, reservationData.value.end_date)) {
        if (startDate) {
          reservationData.value.end_date = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
        }
      }

      await filterDurations(reservationData.value.field_id)
      await calFullPrice()
      await onVerifyRecurringReservation()
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const validatePhone = (e, code) => {
      if (code && e && /^([0-9])*$/.test(e)) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            contactData.value.phone_number = phoneNumberJS.number
            contactData.value.phone_code = phoneNumberJS.country
            contactData.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        phoneInputs.value[0].code = initCode.value
      }
    })

    const onAddContact = item => {
      reservationData.value.contact_id = item.id
      contacts.value = [item]
      contactSelected.value = item
      contactSelectedId.value = item.id
      isContactModalOpen.value = false
      if (formContactDialog.value) formContactDialog.value.reset()
    }

    const resolveFullname = contact => {
      if (contact.fullname) return contact.fullname

      return ''
    }

    const onCreateContact = async () => {
      if (formContactDialog.value.validate()) {
        const bodyContact = {
          fullname: contactData.value.fullname ? title(trimSpace(contactData.value.fullname)) : null,
          phone_code: contactData.value.phone_code,
          phone_prefix: contactData.value.phone_prefix,
          phone_number: contactData.value.phone_number,
          email: contactData.value.email,
          status: 'A',
          categories_ids: contactData.value.categories_ids,
          facility_id: computedFacility.value,
        }
        const resp = await createContact(bodyContact)
        if (resp.ok) {
          bodyContact.id = resp.data.contact_id
          onAddContact(bodyContact)
          success(resp.message)
        } else {
          error(resp.message.text)
        }
      }
    }

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(computedFacility.value || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [
            {
              id: 0,
              fullname: searchQuery.value,
              phone_number: searchQuery.value,
              email: searchQuery.value,
            },
          ]
          contacts.value = newContact.concat(response.data)
        } else contacts.value = []
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 1000)
    }

    const addContact = () => {
      isContactModalOpen.value = true
      if (formContactDialog.value) formContactDialog.value.resetValidation()
      phoneCode.value = initCode.value
      if (searchQuery.value) contactData.value.fullname = searchQuery.value ?? ''
    }

    const onSelectContact = val => {
      if (val) {
        const itemDelected = contacts.value.find(e => e.id === val)
        onAddContact(itemDelected)
      } else if (val === 0) {
        contactSelectedId.value = null

        contacts.value = []
        autoContact.value.reset()
        addContact()
      } else {
        contactSelectedId.value = null
        contacts.value = []
      }
    }

    watch([playersQtySelected], async () => {
      if (reservationData.value.field_id) await calFullPrice(false)
    })

    watch([isContactModalOpen], async () => {
      if (isContactModalOpen.value) {
        const responseCategories = await getContactCategoryActiveFacility(computedFacility.value || 0)
        categories.value = responseCategories.data
      } else {
        // searchQuery.value = ''
        formContactDialog.value.reset()
      }
    })

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => {
          searchQuery.value = firSearch
        }, 50)
      }
      await getDataBySearch()
    })

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        selectedPricingType.value = 0
        if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
        else reservationData.value.group_id = computedGroup.value
        reservationData.value.facility_id = computedFacility.value
        reservationData.value.reservation_date = computedDate.value
        reservationData.value.end_date = moment(reservationData.value.reservation_date || dateNow.value)
          .add(1, 'days')
          .format('YYYY-MM-DD')
        currentDate.value = new Date()

        // reservationData.value.reservation_type = reservationData.value.reservation_type
        fieldList.value = fieldsOptions.value.map(e => ({
          desc: 0,
          title: e.name,
          subtitle: e.sport.name,
          value: e.id,
        }))
        playersQtyLocal.value = reservationData.value.players_qty
        if (computedDataParams.value.category) {
          reservationData.value.start_time = computedDataParams.value.start_time

          const finded = fieldsOptions.value.find(e => e.name === computedDataParams.value.category)
          if (finded) {
            fieldSelected.value = finded
            reservationData.value.field_id = finded.id
            fielsSelected.value = finded.id
            reservationData.value.players_qty = finded.players_qty
            playersQtyLocal.value = finded.players_qty

            // includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`
            playersQtySelected.value = finded.players_qty
            await filterDurations(finded.id)
            initPrice()
            await calFullPrice()
          }
        } else {
          reservationData.value.start_time = computedMinutes.value.at(0).value
          const finded = fieldsOptions.value.at(0)
          fieldSelected.value = finded
          reservationData.value.field_id = finded.id
          fielsSelected.value = finded.id
          reservationData.value.players_qty = finded.players_qty
          playersQtyLocal.value = finded.players_qty

          // includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`
          playersQtySelected.value = finded.players_qty

          await filterDurations(finded.id)
          initPrice()
          await calFullPrice()
        }
      } else {
        isValidateForm.value = false
        selectedPricingType.value = 0
        reservationData.value = {
          // contact_id: null,
          group_id: null,
          facility_id: null,
          field_id: null,
          circle_ids: null,
          user_console_id: null,
          reservation_type: 'flat',
          reservation_date: null,
          start_time: null,
          start_date: null,
          end_date: null,
          reservation_time: 15,
          include_video: 'Y',
          price: null,
          players_qty: null,
          weekdays: [],
          repeat_n_weeks: 1,
          make_recurring: 'N',
          phone_numbers_list: [],
          frequency: 'daily',
        }
        isIncludeAddOns.value = 'Y'

        // remove challenge
        isChallenge.value = 'N'
        pricePerPlayer.value = 0
        phoneInputs.value = [{ value: null, code: null, phone: null }]
        contactSelected.value = null
        contactSelectedId.value = null
        contacts.value = []
        searchQuery.value = ''
        playersGroupData.value = []
        reservationPrice.value = null
        fullPrice.value = 0
        perParticipantPrice.value = 0
        remainingAmount.value = 0
        playersQtyLocal.value = 0
        participants.value = [
          {
            id: null,
            user_match_id: null,
            contact_id: null,
            user_app_id: null,
            fullname: null,
            phone_number: null,
            email: null,
            final_amount: perParticipantPrice.value || 0,
            paid: 'N',
            payment_medium: null,
            onsite_payment_method_id: null,
            onsite_payment_method_name: null,
            empty: true,
            enabled: true,
            is_organizer: 'Y',
            avatar: null,
            checked: false,
            edited: false,
          },
        ]
        emit('update:editable', false)
        emit('initData')
      }
    })

    // remove challenge
    const saveValidation = () => {
      if (
        required(reservationData.value.reservation_date) === true
        && required(reservationData.value.start_time) === true
        && required(reservationData.value.reservation_time) === true
        && required(reservationData.value.end_time) === true
        && required(reservationData.value.field_id) === true
        && (reservationData.value.make_recurring === 'Y' ? required(reservationData.value.frequency) === true : true)
        && (reservationData.value.make_recurring === 'Y' ? required(reservationData.value.end_date) === true : true)
        && required(reservationData.value.price) === true
        && regexValidator(reservationData.value.price, '^[0-9]{0,9}(.?[0-9]{0,2})?$', 'Incorrect format') === true
        && (reservationData.value.reservation_type === 'per_participant'
          ? true
          : participants.value.length && (isChallenge.value === 'Y' ? (participants.value.at(0).empty === false && participants.value.at(1).empty === false) : participants.value.at(0).empty === false))
      ) {
        return true
      }

      return false
    }

    const cancelAction = () => {
      isValidateForm.value = false
      form.value.resetValidation()
      reservationData.value = Object.assign(initialData, {
        // field_id: null,
        // reservation_date: null,
        contact_id: null,
        group_id: null,
        facility_id: null,
        circle_ids: null,
        user_console_id: null,
        reservation_type: 'flat',
        start_time: null,
        reservation_time: 15,
        include_video: 'Y',
        price: null,
        players_qty: null,
        weekdays: [],
        repeat_n_weeks: 1,
        make_recurring: 'N',
        phone_numbers_list: [],
        frequency: 'daily',
      })
      phoneInputs.value = [{ value: null, code: null, phone: null }]
      contactSelected.value = {
        first_name: null,
        last_name: null,
        phone_code: null,
        phone_prefix: null,
        phone_number: null,
        email: null,
        status: 'A',
        categories_ids: [],
      }
      playersGroupData.value = []
      reservationData.value.start_time = computedMinutes.value.at(0).value
      playersQtySelected.value = null
      participants.value = [
        {
          id: null,
          user_match_id: null,
          contact_id: null,
          user_app_id: null,
          fullname: null,
          phone_number: null,
          email: null,
          final_amount: perParticipantPrice.value || 0,
          paid: 'N',
          payment_medium: null,
          onsite_payment_method_id: null,
          onsite_payment_method_name: null,
          empty: true,
          enabled: true,
          is_organizer: 'Y',
          avatar: null,
          checked: false,
          edited: false,
        },
      ]

      // remove challenge
      const setOrganizer = i => {
        if (isChallenge.value === 'Y') {
          return i <= 1 ? 'Y' : 'N'
        }

        return i === 0 ? 'Y' : 'N'
      }

      // remove challenge
      onSelectField(fielsSelected.value)
      for (let i = 0; i < reservationData.value.players_qty - 1; i += 1) {
        participants.value.push({
          id: null,
          user_match_id: null,
          contact_id: null,
          user_app_id: null,
          fullname: null,
          phone_number: null,
          email: null,
          final_amount: perParticipantPrice.value || 0,
          paid: 'N',
          payment_medium: null,
          onsite_payment_method_id: null,
          onsite_payment_method_name: null,
          empty: true,
          enabled: true,
          is_organizer: setOrganizer(i),
          avatar: null,
          checked: false,
          edited: false,
        })
      }
    }

    const confirmCancel = () => {
      Swal.fire({
        title: tInContext('reservations.discart_bookibg_message', vm),
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: tInContext('tooltip.discard', vm),
        cancelButtonText: tInContext('tooltip.cancel', vm),
      }).then(async result => {
        if (result.isConfirmed) {
          cancelAction()
          emit('openClose', false)
        }
      })
    }

    const saveAction = async checkConflicts => {
      const valid = saveValidation()
      if (!valid) {
        isValidateForm.value = true
      } else {
        isValidateForm.value = false
        let resp = null
        let titleMessage = ''
        let message = ''
        let reservationBody = {}

        if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
        else reservationData.value.group_id = computedGroup.value

        if (!props.showAllowVideo) reservationData.value.include_video = 'N'

        const mapPlayers = participants.value.filter(e => e.empty === false)
        const mediun = mapPlayers.filter(e => e.paid === 'Y').length > 0

        if (reservationData.value.make_recurring === 'Y') {
          reservationBody = {
            group_id: reservationData.value.group_id,
            facility_id: computedFacility.value,
            field_id: reservationData.value.field_id,
            user_console_id: userData.value.username,
            reservation_type: reservationData.value.reservation_type,
            event_time: reservationData.value.reservation_time,
            reservation_date: reservationData.value.start_date,
            start_time: getStartTime24(),
            end_time: reservationData.value.end_time,
            include_video: reservationData.value.include_video,
            price: reservationData.value.price,
            players_qty: playersQtySelected.value || reservationData.value.players_qty,
            start_date: reservationData.value.start_date,
            end_date: reservationData.value.end_date,
            frequency: reservationData.value.frequency,
            check_conflicts: checkConflicts || 'Y',
            price_per_player: pricePerPlayer.value || 0,

            contact_id: ['F', 'flat'].includes(reservationData.value.reservation_type && mapPlayers.length) ? mapPlayers.at(0).id : null,
            user_match_organizer: mapPlayers.length ? mapPlayers.at(0) : null,
            players: mapPlayers,
          }

          resp = await createRecurringReservation(reservationBody)
        } else {
          if (!mediun) {
            await calPricePerPlayer()
          }

          reservationBody = {
            group_id: reservationData.value.group_id,
            facility_id: computedFacility.value,
            field_id: reservationData.value.field_id,
            user_console_id: userData.value.username,
            reservation_type: reservationData.value.reservation_type,
            reservation_time: reservationData.value.reservation_time,
            reservation_date: reservationData.value.reservation_date,
            start_time: getStartTime24(),
            end_time: reservationData.value.end_time,
            include_video: reservationData.value.include_video,
            price: reservationData.value.price,
            players_qty: playersQtySelected.value || reservationData.value.players_qty,
            players: mapPlayers,
            contact_id: ['F', 'flat'].includes(reservationData.value.reservation_type && mapPlayers.length) ? mapPlayers.at(0).id : null,
            payment_medium: mapPlayers.filter(e => e.paid === 'Y').length > 0 ? 'console' : null,
            price_per_player: pricePerPlayer.value || 0,
            is_challenge: isChallenge.value,
          }

          resp = await createReservation(reservationBody)
        }

        if (resp.ok) {
          message = resp.message
          emit('refreshEvents')
          cancelAction()
          success(message)
        } else {
          titleMessage = tInContext('reservations.reservation_schedule_conflict', vm)
          if (Array.isArray(resp.data)) message = `${tInContext('reservations.days_with_conflict', vm)}: ${resp.data.join(', ')}`
          Swal.fire({
            title: titleMessage,
            text: `${message}`,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: Array.isArray(resp.data),
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: tInContext('reservations.create_reservations_without_conflict', vm),
            cancelButtonText: tInContext('tooltip.cancel', vm),
          }).then(async result => {
            if (result.isConfirmed) {
              await saveAction('N')
            }
          })
        }
      }
    }

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
      phoneInputs.value[0].code = initCode.value
    })

    return {
      fielsSelected,
      fieldList,
      includeAddOns,
      includeAddOnsContent,
      paymentTypeSelected,
      paymentTypeList,
      playersQtyLocal,

      form,
      isValidateForm,
      isDark,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      playersGroupsOptions,
      computedOnSitePaymentMethods,
      reservationPrice,
      fullPrice,
      perParticipantPrice,
      remainingAmount,
      minuteWarnText,
      endDateWarnText,
      contactWarnText,
      minuteOptions,
      timeOptions,
      recurringFrequencyOptions,
      skillLevelOptions,
      participants,
      playersQtySelected,
      reservationData,
      postData,
      userData,
      modalDate,
      modalStartTime,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      dateNow,
      maxDate,
      minDate,
      computedMinutes,
      endTimeMinutes,
      weekdaysShort,
      fieldSelected,
      includeAddOnsOption,
      recurringAlerts,
      endAfter,

      formDialog,
      isModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      phoneCodeOptions,
      phoneCode,
      phoneNumber,

      formContactDialog,
      contacts,
      categories,
      autoContact,
      isContactModalOpen,
      contactSelectedId,
      contactSelected,
      contactData,
      computedContacts,
      searchQuery,
      searchLoading,

      showOnsitePayment,
      selectedOnsitePayment,
      isSearchGroupModalOpen,
      playersGroupData,
      computedPlayersGroup,

      // computed
      computedIsOpen,
      computedEditable,
      computedIsAdmin,
      computedDataParams,
      startDateComputed,
      endDateComputed,
      minEndDate,

      // i18n
      t,

      // validations
      required,
      emailValidator,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      onlyNumberDot,
      onlyNumbers,
      validatePhone,
      validatePhones,
      isIncludeAddOns,

      // remove challenge
      isChallenge,
      selectedPricingType,

      // Methods
      avatarText,
      saveAction,
      cancelAction,
      confirmCancel,
      onSelectType,
      onSelectDuration,
      calPrice,
      calFullPrice,
      onSelectMakeRecurring,
      onVerifyRecurringReservation,
      onSelectDate,
      onSelectField,
      onSelectAddOns,
      onSelectStartTime,
      onSelectEndTime,
      allowedMinutes,
      allowedHours,
      addPhoneNumber,
      deletePhoneNumber,
      resolveFullname,
      onCreateContact,
      addContact,
      onAddContact,
      sumMinutesOptions,
      formatCurrency,
      onSelectContact,
      onSelectPricingType,
      onIncludeAddOns,

      // remove challenge
      onIncludeChallenge,
      onchangePerParticipantPrice,
      calPricePerPlayer,
      updatePlayersQtyLocal,

      // icons
      icons: {
        mdiCalendar,
        mdiMenuDown,
        mdiClose,
        mdiInformationOutline,
        mdiAlert,
      },
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;
  height: 20px;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }
  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;

    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 0.03125em !important;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}

.theme--light.v-btn--active,
.theme--dark.v-btn--active {
  background-color: #ff3232 !important;
}
</style>

<style lang="scss">
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.14);

  .v-checkbox {
    margin-block-start: -0.375rem;
  }

  .cr-title {
    font-weight: 500;
  }
}

.pricing-type-active,
.v-btn--active {
  background-color: #b1b1b1 !important;
}
</style>
