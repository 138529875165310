var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"multi-col-validation mt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',{staticClass:"mb-5 text-body-1"},[_vm._v(" "+_vm._s(((_vm.$t('reservations.not_created')) + ": " + _vm.computedConflicts))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.computedTableColumns,"items":_vm.dataListTable,"item-key":"id","options":_vm.options,"loading":_vm.loading,"loading-text":_vm.$t('table.loading_text'),"no-data-text":_vm.$t('table.no_data'),"header-props":{sortByText: _vm.$t('table.sort_by')},"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.incomplete_reason",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.incomplete_reason ? item.incomplete_reason : 'No Reason'))])]}},{key:"item.include_video",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.include_video === 'Y' ? item.video_id ? ("id: " + (item.video_id)) : _vm.$t('reservations.scheduled') : _vm.$t('reservations.no_included')))])]}},{key:"item.reservation_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatUSADate(item.reservation_date)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatCurrency(item.price)))])]}},{key:"item.status_str",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status_str)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'C')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"info"},on:{"click":function($event){return _vm.onShow(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.detail')))])]):_vm._e()]}}],null,true)})],1),_c('options-buttom-block',{attrs:{"tab-number":_vm.tab,"is-mode-save-edit":_vm.option !== 2,"no-save":true,"info-required":true,"no-cancel":true,"no-arrow":true,"no-delete":true},on:{"onCancel":function($event){return _vm.$emit('cancel')}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }