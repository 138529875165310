<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-container class="bg-surface-variant">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <statistics-card-horizontal
              :start-title="values.total.startTitle"
              :change="values.total.change"
              :color="values.total.color"
              :icon="values.total.icon"
              :statistics="values.total.statistics"
              :weekoverweek="values.total.weekoverweek"
              :total="values.total.total"
            ></statistics-card-horizontal>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <statistics-card-horizontal
              :change="values.paid.change"
              :color="values.paid.color"
              :icon="values.paid.icon"
              :statistics="values.paid.statistics"
              :start-title="values.paid.startTitle"
              :total="values.paid.total"
            ></statistics-card-horizontal>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <statistics-card-horizontal
              :change="values.lost.change"
              :color="values.lost.color"
              :icon="values.lost.icon"
              :statistics="values.lost.statistics"
              :start-title="values.lost.startTitle"
              :total="values.lost.total"
            ></statistics-card-horizontal>
          </v-col>
        </v-row>
        <v-card
          style="margin-top: 10px; margin-bottom: 10px !important"
          outlined
          class="mb-8"
        >
          <v-card-title>
            {{ "Reservations by Type" }}
          </v-card-title>
          <v-row style="padding-left: 10px !important; padding-right: 10px !important">
            <v-col
              cols="6"
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :change="values.recurring.change"
                :color="values.recurring.color"
                :statistics="values.recurring.statistics"
                :start-title="values.recurring.startTitle"
                :subtitle="values.recurring.subtitle"
                :icon="values.recurring.icon"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :change="values.oneday.change"
                :color="values.oneday.color"
                :icon="values.oneday.icon"
                :statistics="values.oneday.statistics"
                :start-title="values.oneday.startTitle"
                :subtitle="values.oneday.subtitle"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :change="values.pickups.change"
                :color="values.pickups.color"
                :icon="values.pickups.icon"
                :statistics="values.pickups.statistics"
                :start-title="values.pickups.startTitle"
                :subtitle="values.pickups.subtitle"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
            <v-col
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :change="values.academies.change"
                :color="values.academies.color"
                :icon="values.academies.icon"
                :statistics="values.academies.statistics"
                :start-title="values.academies.startTitle"
                :subtitle="values.academies.subtitle"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              class="mb-6"
            >
              <statistics-card-vertical
                :change="values.cancellations.change"
                :icon="values.cancellations.icon"
                :color="values.cancellations.color"
                :statistics="values.cancellations.statistics"
                :start-title="values.cancellations.startTitle"
                :subtitle="values.cancellations.subtitle"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :change="values.noshows.change"
                :color="values.noshows.color"
                :icon="values.noshows.icon"
                :statistics="values.noshows.statistics"
                :start-title="values.noshows.startTitle"
                :subtitle="values.noshows.subtitle"
                :is-outlined="true"
                :show-btn-dots="false"
              ></statistics-card-vertical>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          class="mb-8"
        >
          <v-card-title>
            {{ "Per field reservations:" }}
          </v-card-title>
          <v-card-subtitle>
            {{ `Average: ${values.reserv_qty_avg} per day` }}
          </v-card-subtitle>
          <v-row style="padding-left: 10px !important; padding-right: 10px !important; padding-bottom: 10px !important;">
            <v-col
              v-for="(itemF, i) in values.fields"
              :key="i"
              cols="6"
              sm="4"
              md="2"
            >
              <statistics-card-vertical
                :color="'secondary'"
                :statistics="itemF.statistics"
                :start-title="itemF.startTitle"
                :subtitle="itemF.subtitle"
                :total="''"
                :icon="icons.mdiFocusField"
                :background="'moreDarkGray'"
                :show-btn-dots="false"
                :show-change="false"
                :is-centered="true"
              ></statistics-card-vertical>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          class="mb-8"
        >
          <v-card-title>
            {{ "Locker room activity summary" }}
          </v-card-title>
          <v-row style="margin-left: 10px; margin-right: 10px;">
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <statistics-card-horizontal
                v-for="(item1, i) in items1"
                :key="i"
                style="margin: 10px;"
                :is-centered="false"
                :icon="item1.icon"
                :start-title="item1.text"
                :total="item1.total"
              ></statistics-card-horizontal>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <statistics-card-horizontal
                v-for="(item2, i) in items2"
                :key="i"
                style="margin: 10px;"
                :is-centered="false"
                :icon="item2.icon"
                :start-title="item2.text"
                :total="item2.total"
              ></statistics-card-horizontal>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import StatisticsCardHorizontal from '@/@core/components/statistics-card/StatisticsCardHorizontal.vue'
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  getInsights,
} from '@api'

import
{
  mdiBookClockOutline, mdiNumeric2BoxMultipleOutline, mdiAccountMultiplePlusOutline, mdiSendCheck, mdiScaleBathroom, mdiAccountGroupOutline, mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiHelp, mdiFocusField, mdiCloseCircleOutline, mdiTableCancel, mdiSchoolOutline, mdiCalendarToday, mdiRepeat, mdiArrowDownDropCircleOutline, mdiCalendarCheck, mdiGuitarPickOutline, mdiCurrencyUsd,
} from '@mdi/js'

export default {
  components: {
    StatisticsCardHorizontal,
    StatisticsCardVertical,
  },
  props: {
    dataFechaInicio: {
      type: String,
      default: () => '',
    },
    dataFechaFin: {
      type: String,
      default: () => '',
    },
    dataGroup: {
      type: Number,
      default: () => 0,
    },
    dataFacility: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props) {
    const values = ref({
      total: {
        startTitle: 'Total Reservations',
        statistics: '',
        change: '',
        icon: mdiCalendarCheck,
        color: 'white',
        total: '',
      },
      paid: {
        startTitle: 'Paid',
        statistics: '',
        change: '',
        icon: mdiCurrencyUsd,
        color: 'success',
        total: '',
      },
      lost: {
        startTitle: 'Lost',
        statistics: '',
        change: '',
        icon: mdiArrowDownDropCircleOutline,
        color: 'red',
        total: '',
      },
      recurring: {
        startTitle: 'Recurring',
        color: 'success',
        subtitle: '',
        statistics: '',
        change: '',
        icon: mdiRepeat,
      },
      oneday: {
        startTitle: 'One day',
        icon: mdiCalendarToday,
        color: 'purple',
        subtitle: '',
        statistics: '',
        change: '',
      },
      pickups: {
        startTitle: 'Pick ups',
        icon: mdiGuitarPickOutline,
        color: 'warning',
        subtitle: '',
        statistics: '',
        change: '',
      },
      academies: {
        startTitle: 'Academies',
        icon: mdiSchoolOutline,
        color: 'blue',
        subtitle: '',
        statistics: '',
        change: '',
      },
      cancellations: {
        startTitle: 'Cancellations',
        icon: mdiTableCancel,
        color: 'primary',
        subtitle: '',
        statistics: '',
        change: '',
      },
      noshows: {
        startTitle: 'No Shows',
        icon: mdiCloseCircleOutline,
        color: 'error',
        subtitle: '',
        statistics: '',
        change: '',
      },
      fields: [],
      reserv_qty_avg: 0,
      locker_room_activity: {
        expiration_reminder: 0,
        groups_confirmed: 0,
        groups_confirmed_percent: 0,
        guest_players_invited: 0,
        invitations_sent: 0,
        prior_2_invitations: 0,
        reservations_qty: 0,
      },
    })

    const items1 = ref([
      { text: 'Locker room reservations', icon: mdiScaleBathroom, total: '0' },
      { text: 'Groups confirmed (100%)', icon: mdiAccountGroupOutline, total: '0' },
    ])
    const items2 = ref([
      { text: 'Game invites send', icon: mdiSendCheck, total: '0' },
      { text: 'Expiration reminder', icon: mdiBookClockOutline, total: '0' },
      { text: 'Prioruty "2" game invites', icon: mdiNumeric2BoxMultipleOutline, total: '0' },
      { text: 'Guest players invites', icon: mdiAccountMultiplePlusOutline, total: '0' },
    ])
    const dataComputedFechaInicio = computed(() => (props.dataFechaInicio ? props.dataFechaInicio : ''))
    const dataComputedFechaFin = computed(() => (props.dataFechaFin ? props.dataFechaFin : ''))
    const dataComputedFacility = computed(() => (props.dataFacility ? props.dataFacility : 0))
    const dataComputedGroup = computed(() => (props.dataGroup ? props.dataGroup : 0))
    const { t } = useUtils()
    const modalFrom = ref(null)
    const modalTo = ref(null)
    const minDate = ref(
      new Date('2021-01-02').toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const maxDate = ref(
      new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const nowDate = ref(
      new Date().toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const dateFrom = ref(nowDate.value)
    const dateTo = ref(nowDate.value)
    const dateRangeFilter = ref('')
    const dateRangeOptions = ref([
      { text: t('date_range.this_year'), value: 'TY' },
      { text: t('date_range.this_month'), value: 'TM' },
      { text: t('date_range.last_month'), value: 'LM' },
      { text: t('date_range.this_week'), value: 'TW' },
      { text: t('date_range.last_week'), value: 'LW' },
      { text: t('date_range.custom'), value: 'C' },
    ])
    const fieldsReservation1 = {
      startTitle: 'Field 1',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation2 = {
      startTitle: 'Field 2',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation3 = {
      startTitle: 'Field 3',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation4 = {
      startTitle: 'Field 4',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation5 = {
      startTitle: 'Field 5',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation6 = {
      startTitle: 'Field 6',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const newCustomerOptions = {
      startTitle: 'Total Reservations',
      statistics: '2,856',
      weekoverweek: 'week over week',
      change: '-10.2%',
      icon: mdiCalendarCheck,
      color: 'white',
      total: '26',
    }

    // transparent background card
    const totalRevenueOptions = {
      startTitle: 'Paid',
      statistics: '28.6k',
      change: '+25.8%',
      icon: mdiCurrencyUsd,
      color: 'success',
      total: '26',
    }

    // transparent background card
    const newTransactions = {
      startTitle: 'Lost',
      statistics: '13.6k',
      change: '-25.8%',
      icon: mdiArrowDownDropCircleOutline,
      color: 'red',
      total: '26',
    }
    const newProjectOptions = {
      startTitle: 'Recurring',
      color: 'success',
      subtitle: '$ 1440',
      statistics: '12',
      change: '-18%',
      icon: mdiRepeat,
    }

    const totalProfitOptions = {
      startTitle: 'One day',
      icon: mdiCalendarToday,
      color: 'purple',
      subtitle: '$600',
      statistics: '5',
      change: '+42%',
    }

    const revenueOptions = {
      startTitle: 'Pick ups',
      icon: mdiGuitarPickOutline,
      color: 'warning',
      subtitle: '$240',
      statistics: '2',
      change: '+12%',
    }

    const logisticsOptions = {
      startTitle: 'Academies',
      icon: mdiSchoolOutline,
      color: 'blue',
      subtitle: '$120',
      statistics: '1',
      change: '+12%',
    }

    const reportsOptions = {
      startTitle: 'Cancellations',
      icon: mdiTableCancel,
      color: 'primary',
      subtitle: '$480',
      statistics: '4',
      change: '-8%',
    }

    const transactionsOptions = {
      startTitle: 'No Shows',
      icon: mdiCloseCircleOutline,
      color: 'error',
      subtitle: '$240',
      statistics: '2',
      change: '+12%',
    }
    const ratingsOptions = {
      startTitle: 'Ratings',
      statistics: '13k',
      change: '+38%',
      chipColor: 'primary',
    }

    watch([dataComputedFechaInicio, dataComputedFechaFin], async () => {
      if (dataComputedFacility.value > 0 && dataComputedGroup.value > 0 && dataComputedFechaInicio.value !== '' && dataComputedFechaInicio.value !== '') {
        const respInsights = await getInsights(dataComputedGroup.value, dataComputedFacility.value, dataComputedFechaInicio.value, dataComputedFechaFin.value)
        values.value.total.change = `${parseFloat(respInsights.data.reserv_diff * 100).toFixed(0)}%`
        values.value.total.statistics = respInsights.data.reserv_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        values.value.total.total = respInsights.data.reserv_qty.toString()

        values.value.paid.change = `${parseFloat(respInsights.data.paid_reserv_diff * 100).toFixed(0)}%`
        values.value.paid.statistics = respInsights.data.paid_reserv_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        values.value.paid.total = respInsights.data.paid_reserv_qty.toString()

        values.value.lost.change = `${parseFloat(respInsights.data.lost_reserv_diff * 100).toFixed(0)}%`
        values.value.lost.statistics = respInsights.data.lost_reserv_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        values.value.lost.total = respInsights.data.lost_reserv_qty.toString()

        values.value.recurring.statistics = respInsights.data.reserv_recurring_qty.toString()
        values.value.recurring.subtitle = respInsights.data.reserv_recurring_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.oneday.statistics = respInsights.data.reserv_one_day_qty.toString()
        values.value.oneday.subtitle = respInsights.data.reserv_one_day_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.pickups.statistics = respInsights.data.reserv_pick_up_qty.toString()
        values.value.pickups.subtitle = respInsights.data.reserv_pick_up_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.academies.statistics = respInsights.data.reserv_academies_qty.toString()
        values.value.academies.subtitle = respInsights.data.reserv_academies_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.cancellations.statistics = respInsights.data.reserv_cancellations_qty.toString()
        values.value.cancellations.subtitle = respInsights.data.reserv_cancellations_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.noshows.statistics = respInsights.data.reserv_no_show_qty.toString()
        values.value.noshows.subtitle = respInsights.data.reserv_no_show_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        values.value.fields = respInsights.data.fields.map(itemF => ({ statistics: itemF.reserv_qty, startTitle: itemF.field_name, subtitle: `${(itemF.reserv_percent * 100).toFixed(0)}%` }))

        values.value.reserv_qty_avg = respInsights.data.reserv_qty_avg
        values.value.locker_room_activity = respInsights.data.locker_room_activity

        items1.value[0].total = respInsights.data.locker_room_activity.reservations_qty.toString()
        items1.value[1].total = respInsights.data.locker_room_activity.groups_confirmed.toString()
        items1.value[1].text = `Groups confirmed (${respInsights.data.locker_room_activity.groups_confirmed_percent}%)`

        items2.value[0].total = respInsights.data.locker_room_activity.invitations_sent.toString()
        items2.value[1].total = respInsights.data.locker_room_activity.expiration_reminder.toString()
        items2.value[2].total = respInsights.data.locker_room_activity.prior_2_invitations.toString()
        items2.value[3].total = respInsights.data.locker_room_activity.guest_players_invited.toString()
      }
    })

    return {
      values,
      dataComputedFechaInicio,
      dataComputedFechaFin,
      items1,
      items2,
      dateRangeFilter,
      newCustomerOptions,
      totalRevenueOptions,
      newTransactions,
      newProjectOptions,
      totalProfitOptions,
      reportsOptions,
      logisticsOptions,
      revenueOptions,
      transactionsOptions,
      ratingsOptions,
      fieldsReservation1,
      fieldsReservation2,
      fieldsReservation3,
      fieldsReservation4,
      fieldsReservation5,
      fieldsReservation6,
      dateRangeOptions,
      modalFrom,
      dateFrom,
      modalTo,
      dateTo,
      minDate,
      maxDate,
      nowDate,
      t,
      dataComputedGroup,
      dataComputedFacility,
      icons: {
        mdiCalendarArrowLeft,
        mdiCalendarArrowRight,
        mdiHelp,
        mdiFocusField,
        mdiTableCancel,
      },
    }
  },
}
</script>
