var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs h-full text-body-1 d-flex flex-column",class:{
    'pa-2 pl-3': _vm.smallWidth,
    'px-3 py-1': !_vm.smallWidth,
    'cursor-default': ['blocked_time', 'blocked'].includes(_vm.computedEvent.card_type),
    'border-event': _vm.computedEvent.paid === 'Y',
    'border-event-dotted': _vm.computedEvent.paid === 'N'
  },style:(("background-color: " + (_vm.resolveEventColor(_vm.computedEvent.card_type, _vm.computedEvent.paid)) + "; border-left: solid 5px " + (['blocked_time', 'blocked'].includes(_vm.computedEvent.card_type) ? '#cccccc' : '#FFB400') + "; min-height: " + (_vm.$vuetify.breakpoint.smAndDown ? '104px' : '96px') + ";"))},[_c('div',{staticClass:"d-flex align-center my-1"},[(_vm.computedEvent.card_type === 'reservation')?_c('v-chip',{staticClass:"mr-2 text-xs",staticStyle:{"text-transform":"uppercase"},attrs:{"color":"black","small":""}},[_vm._v(" "+_vm._s(_vm.computedEvent.payment_status_str)+" ")]):_vm._e(),_vm._t("share-icon"),(_vm.computedEvent.card_type === 'reservation' && _vm.computedEvent.recurring_id)?_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"25","color":"white"}},on),[_vm._v(" "+_vm._s(_vm.icons.mdiSync)+" ")])]}}],null,false,3576766170)},[_c('span',[_vm._v(_vm._s(((_vm.$t('reservations.recurring')) + " #" + (_vm.computedEvent.recurring_id))))])])],1):_vm._e(),(_vm.computedEvent.card_type === 'reservation' && _vm.computedEvent.include_video !== 'Y')?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"25","color":"black"}},[_vm._v(" "+_vm._s(_vm.icons.mdiVideoOutline)+" ")]):_vm._e()],2),_c('div',{staticClass:"text-body-1 font-weight-bold my-auto black--text d-flex align-center",staticStyle:{"text-wrap":"pretty","line-height":"1.8rem"}},[(_vm.computedEvent.card_type === 'reservation' || _vm.computedEvent.time_mins > 15)?_c('span',{staticClass:"d-flex flex-column"},[(_vm.computedEvent.card_type === 'reservation')?_c('span',[_vm._v(_vm._s(((_vm.computedEvent.contact_name) + " (" + (_vm.computedEvent.players) + ")") || _vm.$t('reservations.per_participant')))]):_vm._e(),(_vm.computedEvent.card_type === 'blocked_time' && _vm.computedEvent.time_mins > 30)?_c('span',{style:('opacity: 0.8')},[_vm._v(_vm._s(_vm.$t('fields.blocked_time')))]):_vm._e(),(_vm.computedEvent.card_type === 'blocked_time' && _vm.computedEvent.time_mins <= 30)?_c('span',[_vm._v(_vm._s(_vm.$t('reservations.blocked')))]):_vm._e(),(!['reservation', 'blocked_time'].includes(_vm.computedEvent.card_type))?_c('span',[_vm._v(_vm._s(_vm.$t('fields.blocked_by_shared_field')))]):_vm._e(),(_vm.computedEvent.card_type !== 'reservation')?_c('span',{staticClass:"text-xs black--text tiny-spacing font-weight-regular",style:('opacity: 0.8')},[_vm._v(_vm._s(_vm.computedTime))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"d-flex align-center my-1"},[(_vm.computedEvent.card_type === 'reservation')?_c('span',{staticClass:"text-xs black--text tiny-spacing"},[_vm._v(_vm._s(_vm.computedTime))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }