<template>
  <v-card
    outlined
    style="padding-right: 1.25rem;"
    class="statistics-horizontal"
  >
    <div
      style="width: fit-content; margin-left: auto; margin-right: auto;"
      class="d-flex align-center"
    >
      <v-avatar
        :color="$vuetify.theme.dark ? '#312d4b': '#fff'"
        rounded
        size="44"
        class="elevation-3"
      >
        <v-icon
          size="30"
          :color="color"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>

      <div class="ms-3">
        <span
          :style="change === '' ? { fontSize: '20px !important' } : {}"
          class="text-xs"
        >{{ startTitle }}</span>
        <p class="text-no-wrap mb-0">
          <span
            class="text-xl font-weight-semibold text--primary"
          >{{ statistics }}</span>
          <v-icon
            v-if="change !== ''"
            size="20"
            :color="checkChange(change) ? 'success':'error'"
          >
            {{ checkChange(change) ?mdiChevronUp :mdiChevronDown }}
          </v-icon>
          <span
            v-if="change !== ''"
            class="font-weight-semibold text-xs"
            :class="checkChange(change) ? 'success--text':'error--text'"
          >{{ change }}</span>
        </p>
      </div>
      <div class="ms-3">
        <p class="text-no-wrap mb-0">
          <span
            style="font-size: 55px !important;"
            class="text-xl font-weight-semibold text--primary"
          >{{ total }}</span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
  props: {
    startTitle: { type: String, default: '' },
    statistics: { type: String, default: '' },
    total: { type: String, default: '' },
    change: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '-') {
        return false
      }

      return true
    }

    return {
      mdiAccountOutline,
      mdiChevronDown,
      mdiChevronUp,
      checkChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.statistics-horizontal {
  background-color: transparent !important;
  padding: 1.25rem 4.063rem 1.125rem 1.25rem;
}

.v-application {
  &.v-application--is-rtl {
    .statistics-horizontal {
      padding: 1.25rem 1.125rem 1.25rem 4.063rem;
    }
  }
}
</style>
